import React from "react";
import Banner from "../common/banner/banner";
import bg3 from "./libraryBanner.jpg";
import { MuiFbPhotoGrid } from "mui-fb-photo-grid";
import { gallery_images_data } from "./lib_img";

const Library = () => {

    const gallery_images = [];

    gallery_images_data.map((x => {
        gallery_images.push(
            {
                title: " Librarry Gallery",
                img: x.images,
                imgThumbnail: x.images
            }
        )
    }))


    return (
        <>
            <Banner
                Image={bg3}
                Title={"University Library"}
                breadcrumb={["Resources", "Library"]}
                height="200px"
            />
            <div className="container-fluid layout col1 pt-4 mb-5 pb-3" style={{ margin: "20px" }}>
                <div className="row">
                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <div className="row">
                                <div className="container text-justify">
                                    <div className="row header">
                                        <div className="col-sm-4">
                                            <img width={'100%'} src={require("./liberian.jpg")}
                                                alt={'Al-anar library'} />
                                            <h5 className="text-center mt-2">Dr. Samaila Inuwa <br />University Librarian</h5>
                                        </div>
                                        <div className="col-sm-8">
                                            <p>The University library is the nerve center of the University. The library is established alongside with Al-Ansar University for the purpose of promoting teaching, learning and research in the university. The objectives of Al-Ansar University library are to: </p>
                                            {/* <ul className="mb-4 list-unstyled"> */}
                                            <ul className="mb-4">
                                                <li className="mb-2">To make library information resources available to students and staff in support of the learning process.</li>
                                                <li className="mb-2">To provide library information materials to our clients for their own personal self-development.</li>
                                                <li className="mb-2">To cooperate with other sister University Libraries in order to develop excellent library information resources.</li>
                                                <li className="mb-2">To meet the information needs of the people within which the library is located. </li>
                                            </ul>
                                            <p> For convenience and ease of management, the library is organized into six divisions. These are Administrative division, Collection Development Division, Cataloguing and Classification Division, Readers Services Division, Serials section, as well as E-library Division. </p>
                                            <p>Each of the divisions are responsible for specific duties and they are to cooperate with one another in order to function effectively and efficiently. The duties of the above listed divisions are as follows:</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container mt-5">
                        <p>
                            <i className=""><strong>Administrative Division:</strong></i> The Administrative division is responsible for the day-to-day running of the University Library with the University Librarian as the officer in-charge. The Administration is saddled with the responsibilities of staff training and development, appraisal, welfare and discipline. a time.
                        </p>
                    </div>

                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className="accordion" id="career-opportunities">
                                    <div className="card">
                                        <div className="accordion-icon card-header" id="headingOne">
                                            <h4 className="mb-0">
                                                <button className="btn" type="button" data-toggle="collapse"
                                                    data-target="#security-manager" aria-expanded="true"
                                                    aria-controls="security-manager">
                                                    i.	Collection Development Division
                                                </button>
                                            </h4>
                                        </div>
                                        <div id="security-manager" className="collapse show"
                                            aria-labelledby="headingOne"
                                            data-parent="#career-opportunities">
                                            <div className="card-body">
                                                The Collection Development Division is primarily responsible for the selection, ordering, receiving and processing of all library materials. These materials include books and non-book materials.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="accordion-icon card-header" id="headingTwo">
                                            <h4 className="mb-0">
                                                <button className="btn" type="button" data-toggle="collapse"
                                                    data-target="#vision" aria-expanded="false"
                                                    aria-controls="vision">
                                                    ii.	Cataloguing and Classification Division
                                                </button>
                                            </h4>
                                        </div>
                                        <div id="vision" className="collapse"
                                            aria-labelledby="headingTwo"
                                            data-parent="#career-opportunities">
                                            <div className="card-body">
                                                Books and non-book materials that have been acquired and processed by the collection Development Division are then sent to the Cataloguing Division, it is in this Division that books and non-book materials are catalogued, classified and lettered. When the processes are completed, materials are then sent to various sections in the main library.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="accordion-icon card-header" id="headingThree">
                                            <h4 className="mb-0">
                                                <button className="btn" type="button" data-toggle="collapse"
                                                    data-target="#vision1" aria-expanded="false"
                                                    aria-controls="vision1">
                                                    iii. Reader Services Division
                                                </button>
                                            </h4>
                                        </div>
                                        <div id="vision1" className="collapse"
                                            aria-labelledby="headingThree"
                                            data-parent="#career-opportunities">
                                            <div className="card-body">
                                                The Division may also be referred to as “Readers Welfare Division” Here readers are registered although this function may be decentralized to other service units to ease congestion. The Division is made up of Circulation, Short Term Loan and the Reference Units.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="accordion-icon card-header" id="headingFour">
                                            <h4 className="mb-0">
                                                <button className="btn" type="button" data-toggle="collapse"
                                                    data-target="#vision2" aria-expanded="false"
                                                    aria-controls="vision2">
                                                    iv. Serials Division
                                                </button>
                                            </h4>
                                        </div>
                                        <div id="vision2" className="collapse"
                                            aria-labelledby="headingFour"
                                            data-parent="#career-opportunities">
                                            <div className="card-body">
                                                The Division is responsible for all serial publication acquired and processed. It includes, periodicals, memoirs, proceedings and transactions, etc. The Division is also in-charge of current and back issues of serials and the distribution of newspapers/magazines.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="accordion-icon card-header" id="headingFive">
                                            <h4 className="mb-0">
                                                <button className="btn" type="button" data-toggle="collapse"
                                                    data-target="#vision3" aria-expanded="false"
                                                    aria-controls="vision3">
                                                    v. The E-library
                                                </button>
                                            </h4>
                                        </div>
                                        <div id="vision3" className="collapse"
                                            aria-labelledby="headingFive"
                                            data-parent="#career-opportunities">
                                            <div className="card-body">
                                                The E-library is responsible for disseminating e-resources of the library to students and staff. It provides information resources to clients at their fingertips where ever they are once there is internet.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container mt-5">
                        <h4>DATABASES AVAILABLE IN THE LIBRARY</h4>
                        <ul className="mb-4">
                            <p className="mb-2"><a href="https:jstor.org/">1.	Jstor</a></p>
                            <p className="mb-2"><a href="https://portal.igpublish.com/iglibrary/">2.	IG Publishing library</a></p>
                        </ul>
                    </div>

                    <div className="container mt-5">
                        <h4>LIBRARY SPACE</h4>
                        <p>
                            The University library have standing shelves and wall shelves that can contain a large number of books for students and staff. The library has a seating capacity of ninety (90) students at a time, while the e-library can comfortably accommodate forty (40) students at a time.
                        </p>
                        <div className="row">
                            <div className="layout-col col2-50 layout-7 col-md-7">
                                <div className="layout-blocks-ucws-image container-fluid roundable block image">
                                    <div className="row">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="image-wrapper" style={{ width: "100%", borderRadius: '5px', minHeight: '30%', maxHeight: '30%' }}>
                                                        <MuiFbPhotoGrid
                                                            images={gallery_images} // require
                                                            reactModalStyle={{ overlay: { zIndex: 200 } }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}

export default Library;