import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./navigationbar.css";
import Logo from "../../../images/logo.png";
import { setFacultyListDetails } from "../../../actions/setactiondetails";
import { connect } from "react-redux";
import axios from "axios";
import {
  projectEmail,
  projectFacebook, projectInstagram, projectLinkedIn,
  projectName, projectPaymentURL,
  projectPhone, projectStaffURL, projectStudentURL,
  projectTwitter,
  serverLink
} from "../../../resources/url";

function NavigationBar(props) {
  const location = useLocation();
  const [closeNav, setCloseNav] = useState(false)


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  const getFacultyList = async () => {
    await axios
      .get(`${serverLink}academics/faculty/academic/list`)
      .then((response) => {
        props.setOnFacultyListDetails(response.data);
      })
      .catch((error) => {
        console.log("NETWORK ERROR", error);
      });
  };
  useEffect(() => {
    getFacultyList();
  }, []);

  return (
    <header className="header header-sticky">
      <div className="topbar bg-dark py-3 d-none d-lg-flex">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="d-none d-lg-flex align-items-center text-center">
                <div className="mr-3 d-inline-block">
                  <a className="text-white" href={`mailto:${projectEmail}`}><i className="far fa-envelope mr-2" /><span>Email:</span> {projectEmail}</a>
                </div>
                <div className="mr-auto d-inline-block">
                  <a className="text-white" href={`tel:${projectPhone}`}><i className="fas fa-phone-alt mr-2" /><span>Call</span> {projectPhone}</a>
                </div>
                <div className="mr-3 d-inline-block">
                  <a className="text-white" href="mailto:gethelp@university.com"><i className="far fa-clock mr-2" /><span>Opening:</span> 09:00am
                    - 5:00pm</a>
                </div>
                <div className="social-icon">
                  <ul className="social-icon">
                    <li>
                      <a href={projectFacebook}><i className="fab fa-facebook-f" /></a>
                    </li>
                    <li>
                      <a href={projectTwitter}><i className="fab fa-twitter" /></a>
                    </li>
                    <li>
                      <a href={projectLinkedIn}><i className="fab fa-linkedin-in" /></a>
                    </li>
                    <li>
                      <a href={projectInstagram}><i className="fab fa-instagram" /></a>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-main py-3">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="d-lg-flex align-items-center">
                <a href="/">
                  <img src={Logo} alt={projectName} width={80} />
                </a>


                <nav className="navbar navbar-expand-lg">

                  <button className={"navbar-toggler show"}
                    aria-expanded={"false"} aria-label="Toggle navigation" onClick={() => { setCloseNav(!closeNav) }}>
                    <span className="navbar-toggler-icon" />
                  </button>


                    <div className={`collapse navbar-collapse justify-content-center ${closeNav === true ? "collapse show" : "collapse"}`} id="navbarSupportedContent">
                      <ul className="navbar-nav">
                        <li className="nav-item">
                          <a href={"/"} className="nav-link">
                            Home
                          </a>
                        </li>

                        <li className="nav-item dropdown">
                          <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">About<i className="fas fa-chevron-down fa-xs" />
                          </a>

                          <ul className="dropdown-menu">
                            <li className="dropdown-item" onClick={() => { setCloseNav(false) }}><Link to={"/about-us"} className="nav-link" >About Us</Link></li>
                            <li className="dropdown-item" onClick={() => { setCloseNav(false) }}><Link to={"/our-campus"} className="nav-link">Our Campus</Link></li>
                            <li className="dropdown-item" onClick={() => { setCloseNav(false) }}><Link to={"/the-founder"} className="nav-link">The Founder</Link></li>
                            <li className="dropdown-item" onClick={() => { setCloseNav(false) }}><Link to={"/board"} className="nav-link">Board of Trustee</Link></li>
                            <li className="dropdown-item" onClick={() => { setCloseNav(false) }}><Link to={"/governing-council"} className="nav-link">Governing Council</Link></li>
                            <li className="dropdown-item" onClick={() => { setCloseNav(false) }}><Link to={"/management"} className="nav-link">Management</Link></li>
                            <li className="dropdown-item" onClick={() => { setCloseNav(false) }}><Link to={"/senate"} className="nav-link">Senate</Link></li>
                          </ul>
                        </li>

                        <li className="nav-item dropdown">
                          <a className="nav-link  dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Admission <i className="fas fa-chevron-down fa-xs" />
                          </a>

                          <ul className="dropdown-menu">
                            <li className="dropdown-item" onClick={() => { setCloseNav(false) }}><Link to={"/course-finder"} className="nav-link">Course Finder</Link></li>
                            <li className="dropdown-item" onClick={() => { setCloseNav(false) }}><Link to={"/fees"} className="nav-link">Fees</Link></li>
                            <li className="dropdown-item" onClick={() => { setCloseNav(false) }}><Link to={"/apply-now"} className="nav-link">Apply Now</Link></li>
                          </ul>
                        </li>

                        <li className="nav-item dropdown">
                          <a className="nav-link  dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Colleges<i className="fas fa-chevron-down fa-xs" />
                          </a>

                          <ul className="dropdown-menu" style={{ width: "700px" }}>
                            {props.facultyList.length > 0 &&
                              props.facultyList.map((faculty, index) => {
                                return (
                                  <li key={index} className="dropdown-item" onClick={() => { setCloseNav(false) }}>
                                    <Link to={`/faculty/${faculty.Slug}`} className="nav-link"> {faculty.FacultyName}</Link></li>
                                );
                              })}
                          </ul>
                        </li>

                        <li className="nav-item dropdown">
                          <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Academics<i className="fas fa-chevron-down fa-xs" />
                          </a>

                          <ul className="dropdown-menu">
                            <li className="dropdown-item" onClick={() => { setCloseNav(false) }}><Link to={"/staff-directorate"} className="nav-link">Staff Directorate</Link></li>
                            <li className="dropdown-item" onClick={() => { setCloseNav(false) }}><Link to={"/learning-goal"} className="nav-link">Learning Goal</Link></li>
                            <li className="dropdown-item" onClick={() => { setCloseNav(false) }}><Link to={"/academic-registry"} className="nav-link">Academic Registry</Link></li>
                            <li className="dropdown-item" onClick={() => { setCloseNav(false) }}><Link to={"/academic-calender"} className="nav-link">Academic Calender</Link></li>
                          </ul>
                        </li>

                        <li className="nav-item dropdown">
                          <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Resources<i className="fas fa-chevron-down fa-xs" />
                          </a>

                          <ul className="dropdown-menu">
                            <li className="dropdown-item" onClick={() => { setCloseNav(false) }}><Link to={"/news"} className="nav-link">News</Link></li>
                            <li className="dropdown-item" onClick={() => { setCloseNav(false) }}><Link to={"/events"} className="nav-link">Event</Link></li>
                            <li className="dropdown-item" onClick={() => { setCloseNav(false) }}><Link to={"/gallery"} className="nav-link">Gallery</Link></li>
                            {/*<li className="dropdown-item" onClick={() => { setCloseNav(false) }}><Link to={"/publication"} className="nav-link">Publication</Link></li>*/}
                            <li className="dropdown-item" onClick={() => { setCloseNav(false) }}><Link to={"/careers"} className="nav-link">Career</Link></li>
                            <li className="dropdown-item" onClick={() => { setCloseNav(false) }}><Link to={"/library"} className="nav-link">Library</Link></li>
                          </ul>
                        </li>

                        <li className="nav-item dropdown">
                          <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Portals<i className="fas fa-chevron-down fa-xs" />
                          </a>

                          <ul className="dropdown-menu">
                            <li className="dropdown-item" onClick={() => { setCloseNav(false) }}><a className="nav-link" href={projectStudentURL}>Student Portal</a></li>
                            <li className="dropdown-item" onClick={() => { setCloseNav(false) }}><a className="nav-link" href={projectStaffURL}>Staff Portal</a></li>
                            <li className="dropdown-item" onClick={() => { setCloseNav(false) }}><Link to={"/apply-now"} className="nav-link">Admission Portal</Link></li>
                            <li className="dropdown-item" onClick={() => { setCloseNav(false) }}><a className="nav-link" href={projectPaymentURL}>Payment Portal</a></li>
                          </ul>
                        </li>



                        <li className="nav-item">
                          <Link to={"/contact-us"} onClick={() => { setCloseNav(false) }} className="nav-link">Contact Us</Link>
                        </li>
                      </ul>
                    </div>


                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

const mapStateToProps = (state) => {
  return {
    facultyList: state.FacultyListDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnFacultyListDetails: (p) => {
      dispatch(setFacultyListDetails(p));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
