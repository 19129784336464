import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {formatDateAndTime, projectLogo, serverLink, shortCode} from "../../../resources/url";
import Logo from "../../../images/logo.png"
import * as DOMPurify from 'dompurify';

function LatestEventSection(props) {
    return (
        <section className="space-pt">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="section-title">
                                    <h2 className="title">Latest Events</h2>
                                </div>
                            </div>
                            <div className="col-md-5 text-md-right mb-4 mb-md-0">
                                <Link className="btn btn-primary btn-round" to="/events">View All</Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="events">
                                    <div className="row">
                                        <div className="col-12">
                                            {
                                                props.events.length > 0 ?
                                                    props.events.sort((a, b) => b.EventDate - a.EventDate).slice(0,3).map((event, index) => 
                                                    {
                                                        if (index < 3) {
                                                            const img = event.ImagePath !== null ? event.ImagePath.includes('simplefileupload.com') ? event.ImagePath : `${serverLink}public/uploads/${shortCode}/event_uploads/${event.ImagePath}`: Logo;
                                                            return <div key={index} className="events shadow p-4 bg-white border-radius mb-4">
                                                                <div className="row">
                                                                    <div className="col-lg-3">
                                                                        <div className="events-img mb-4 mb-lg-0">
                                                                            <img className="img-fluid border-0" src={img} alt={event.Title} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 align-self-center">
                                                                        <div className="events-content">
                                                                            <Link to={`/events/${event.Slug}`} className="text-dark h6">{event.Title}</Link>
                                                                            <div className="events-meta my-3">
                                                                                <ul className="list-unstyled mb-0 d-flex">
                                                                                    <li className="mr-3"><a href="#"><i
                                                                                        className="fas fa-calendar-alt pr-1"/> {formatDateAndTime(event.EventDate, 'date')}</a></li>
                                                                                    <li><a href="#"><i
                                                                                        className="fas fa-clock pr-1"/> {event.StartTime} - {event.EndTime}</a></li>
                                                                                </ul>
                                                                            </div>
                                                                            <p className="text-dark mb-lg-0" dangerouslySetInnerHTML={{__html:  DOMPurify.sanitize(event.Description.substring(0,100)) }} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 align-self-center text-lg-right text-left">
                                                                        <Link className="btn btn-dark btn-round" to={`/events/${event.Slug}`}>Read More</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    }) 
                                                    : <div className="alert alert-info">No Upcoming Event!</div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (state) => {
    return {
        events: state.EventDetails
    };
};

export default connect(mapStateToProps, null)(LatestEventSection);