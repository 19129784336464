import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function CourseFinderSide(props) {
    const app_type = [];
    if (props.courseList.length > 0) {
        props.courseList.map((course) => {
            app_type.push(course.ApplicationType);
        });
    }
    const unique_app_type = app_type.filter((v, i, a) => a.indexOf(v) === i);

    return (
        <div className="col-lg-3">
           
                <div className="course-sidebar shadow">

                    <div className="widget">
                        <div className="widget-title">
                            <h5>Search</h5>
                        </div>
                        <form className="navbar-form search d-flex">
                            <input type="text" name="q" className="form-control input-search rounded-0 bg-white" placeholder="Search" onChange={props.onCourseChange} autoComplete="off" />
                            <a><i className="fa fa-search text-white" /></a>
                        </form>
                    </div>

                    <hr />
                    <div className="widget">
                        <div className="widget-title">
                            <h5>Find Course</h5>
                        </div>
                        <form className="navbar-form search d-flex">
                        <select
                        name=""
                        id=""
                        className="form-control"
                        onChange={props.onCourseChange}
                    >
                        <option value="">Select Course</option>
                        <option value="">All Courses</option>
                        {props.courseList.length > 0 &&
                            props.courseList.map((course, index) => {
                                return (
                                    <option key={index} value={course.Slug}>
                                        {course.CourseName}
                                    </option>
                                );
                            })}
                    </select>
                        </form>
                    </div>

                    {unique_app_type.length > 1 &&
                    <div className="widget">
                        <div className="widget-title">
                            <h5>Categories</h5>
                        </div>
                        <div className="widget-categories">
                            <ul className="list-unstyled list-style mb-0">
                                {unique_app_type.length > 0 &&
                                    unique_app_type.map((app, index) => {
                                        return (
                                            <li
                                                key={index}
                                                onClick={() => props.handleOnApplicationTypeClick(app)}
                                            >
                                                <Link to={""} className="text-capitalize">
                                                    {app} (
                                                    {
                                                        props.courseList.filter(
                                                            (item) => item.ApplicationType === app
                                                        ).length
                                                    }
                                                    )
                                                </Link>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    </div>}
                    <hr />

                    <div className="widget">
                        <div className="widget-title">
                            <h5>Faculties</h5>
                        </div>
                        <div className="widget-categories">
                            <ul className="list-unstyled list-style mb-0">
                                {props.facultyList.length > 0 &&
                                    props.facultyList.map((faculty, index) => {
                                        return (
                                            <li
                                                key={index}
                                                onClick={() =>
                                                    props.handleOnFacultyClick(faculty.FacultyCode)
                                                }
                                            >
                                                <Link to={""}>
                                                    {faculty.FacultyName} (
                                                    {
                                                        props.courseList.filter(
                                                            (item) => item.FacultyCode === faculty.FacultyCode
                                                        ).length
                                                    }
                                                    )
                                                </Link>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    </div>

                    <hr />
                </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        facultyList: state.FacultyListDetails,
        courseList: state.CourseListDetails,
    };
};

export default connect(mapStateToProps, null)(CourseFinderSide);
