import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { formatDateAndTime, serverLink, shortCode } from "../../../../resources/url";
import SideSearch from "./sidebar";
import {
    setGeneralDetails, setNewsDetails,
} from "../../../../actions/setactiondetails";
import axios from "axios";
import { useParams } from "react-router-dom";
import Loader from "../../../common/loader/loader";
import * as DOMPurify from 'dompurify';

function NewsDetailsContent(props) {
    const params = useParams();
    const slug = params.slug
    const [loading, setLoading] = useState(true);
    const [newsList, setNewsList] = useState(props.newsList.length > 0 ? props.newsList : []);
    const [newsList2, setNewsList2] = useState(props.newsList.length > 0 ? props.newsList : []);
    const [newsList3, setNewsList3] = useState(props.newsList.length > 0 ? props.newsList : []);
    const [newsData, setnewsData] = useState(props.newsData !== undefined ? props.newsData : '');
    const [n_data, setNdata] = useState([props.newsData])

    const getData = async () => {
        await axios
            .get(`${serverLink}news/list/all/General`)
            .then((result) => {
                const data = result.data.filter(x => x.Slug === slug);
                setnewsData(data[0])
                setNewsList(data);
                setNewsList2(result.data);
                setNewsList3(result.data);
                props.setOnNewsDetails(data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    };
    const handleSearch = (e) => {
        let filtered = newsList2.filter(x => x.Title.toLowerCase().includes(e.target.value.toLowerCase()));
        setNewsList(filtered)
    }

    const [change, setChange] = useState("");

    useEffect(() => {
      getData();
    }, [change]);
  
    const changeScreen = (items) => {
      setChange(items);
    };


    return loading ? (<Loader />) : <section className="" style={{ marginTop: "-120px" }}>
        <div className="container">
            <div className="row">
                <div className="col-lg-4 mb-5 mb-lg-0">
                    <SideSearch
                        handleSearch={handleSearch}
                        newsList={newsList}
                        newsList3={newsList3}
                        newsList2={newsList2}
                        newsData={newsData}
                        setGeneralDetails={props.setOnGeneralDetails}
                        channgeScreen={changeScreen}
                    />
                </div>
                {
                    newsData !== undefined &&
                    <div className="col-lg-8 mb-5">
                        <div className="events">
                            <div className="events-img">
                                <img className="img-fluid border-0" src={newsData.ImagePath !== null ? newsData.ImagePath.includes("simplefileupload") ? newsData.ImagePath : `${serverLink}public/uploads/${shortCode}/news_uploads/${newsData.ImagePath}` : ""} alt="" />
                            </div>
                            <div className="events-content mt-4">
                                <div className="events-tag">
                                    <a href="#" className="badge-danger mb-2">{newsData.FacultyCode}</a>
                                </div>
                                <p className="text-dark h5">{newsData.Title}</p>
                                <div className="events-meta my-2">
                                    <ul className="list-unstyled mb-0 d-flex">
                                        <li className="mr-3"><a href="#"><i className="fas fa-calendar-alt pr-1"></i> {formatDateAndTime(newsData.InsertedDate, "date")}</a></li>
                                        <li><a href="#"><i className="fas fa-clock pr-1"></i> {newsData.FacultyCode}</a></li>
                                    </ul>
                                </div>
                                <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(newsData.Description) }} />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </section>

}

const mapStateToProps = (state) => {
    return {
        newsData: state.GeneralDetails,
        newsList: state.NewsDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        },
        setOnNewsDetails: (p) => {
            dispatch(setNewsDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewsDetailsContent);

