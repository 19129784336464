import React, { useEffect, useState } from "react";
import Slider from "./section/slider";
import Loader from "../common/loader/loader";
import { connect } from "react-redux";
import {
  setEventDetails, setHomeSliderDetails,
  setNewsDetails,
} from "../../actions/setactiondetails";
import axios from "axios";
import { projectName, serverLink } from "../../resources/url";

import PageName from "../common/pagename/pagename";
import FindCourseSection from "./section/find-course-section";
import CounterSection from "./section/counter-section";
import WelcomeSection from "./section/welcome-section";
import WhyUsSection from "./section/why-us-section";
import LatestEventSection from "./section/latest-event-section";
import LatestNewsSection from "./section/latest-news-section";
import PartnersSection from "./section/partners-section";
import DataLoader from "../common/dataLoader/dataLoader";
import Ticker from 'react-ticker'
import {Link} from "react-router-dom";


function Homepage(props) {
  const [loading, setLoading] = useState(props.slider.length < 1);

  const getData = async () => {
    await axios
      .get(`${serverLink}slider/homepage`)
      .then((result) => {
        props.setOnSliderDetails(result.data);
        setLoading(false)
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loading ? (
        <DataLoader />
      ) : (
        <>
          <PageName page={"Homepage"} />
          <Slider />
          <FindCourseSection />
          {/* <Ticker mode="await">
            {({ index }) => (
                <>
                  <h4>Admission is open for September semester. <Link to="/apply-now" style={{color: '#a61d37'}}>Apply Now</Link></h4>
                </>
            )}
          </Ticker> */}
          <CounterSection />
          <WelcomeSection />
          <WhyUsSection />
          <LatestEventSection />
          <LatestNewsSection />
          <PartnersSection />
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    eventList: state.EventDetails,
    newsList: state.NewsDetails,
    slider: state.SliderDetails
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnEventDetails: (p) => {
      dispatch(setEventDetails(p));
    },
    setOnNewsDetails: (p) => {
      dispatch(setNewsDetails(p));
    },
    setOnSliderDetails: (p) => {
      dispatch(setHomeSliderDetails(p));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
