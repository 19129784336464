export const subjects = [
    "Additional General Science",
    "Additional Mathematics",
    "Agricultural Science",
    "Applied Mathematics",
    "Biology",
    "Botany",
    "Chemistry",
    "Civic Education",
    "CRS/IRS",
    "Commerce",
    "English Language",
    "Economics",
    "Food Science",
    "Food and Nutrition",
    "Further Mathematics",
    "Fine and Applied Arts",
    "French",
    "General Biology",
    "General Science",
    "Government",
    "Geography",
    "Geology",
    "Geography",
    "Health Science",
    "Human Biology",
    "Hygiene",
    "History",
    "Integrated Science",
    "Igbo",
    "Literature in English",
    "Mathematics",
    "Marketing",
    "Music",
    "Other Nigerian Languages",
    "Physics",
    "Pure and Applied Mathematics",
    "Principles of Accounts",
    "Rural Biology",
    "Rural Science",
    "Statistics",
    "Zoology",
];