import React from "react";
import Foundation from "../../../images/partners/al-foundation.jpg"
import University from "../../../images/partners/aum.png"
import Masjid from "../../../images/partners/masjid-maiduguri.png"
import Security from "../../../images/partners/al-security.jpg"
import AcademySuleja from "../../../images/partners/alaasu.png"
import AcademyKano from "../../../images/partners/alaaka.jpeg"
import Cleaning from "../../../images/partners/al-cleaning.jpg"
import Radio from "../../../images/partners/al-fm.png"
import Hospital from "../../../images/partners/al-hospital.jpg"
import {projectURL} from "../../../resources/url";

function PartnersSection() {
    return (
        <section className="py-5">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="owl-carousel our-clients bg-white p-0" data-nav-arrow="false" data-items="5"
                             data-md-items="4" data-sm-items="3" data-xs-items="2" data-xx-items="1" data-space="0"
                             data-autoheight="true">
                            <div className="item">
                                <a href="http://www.al-ansar-foundation.com" referrerPolicy="no-referrer" className="text-dark" target="_blank">
                                    <img className="img-fluid center-block mx-auto grayscale" src={Foundation} alt="Al-Ansar Foundation" title={"Al-Ansar Foundation"}/>
                                    <p className="text-center">Al-Ansar Foundation</p>
                                </a>
                            </div>

                            <div className="item">
                                <a href={projectURL} referrerPolicy="no-referrer" className="text-dark" target="_blank">
                                    <img className="img-fluid center-block mx-auto grayscale" src={University} alt="Al-Ansar University"/>
                                    <p className="text-center">Al-Ansar University</p>
                                </a>
                            </div>

                            <div className="item">
                                <a href="https://www.al-ansarmasjid.com" referrerPolicy="no-referrer" className="text-dark" target="_blank">
                                    <img className="img-fluid center-block mx-auto grayscale" src={Masjid} alt="Al-Ansar Majid"/>
                                    <p className="text-center">Al-Ansar Masjid</p>
                                </a>
                            </div>

                            <div className="item">
                                <a href="https://www.al-ansar-security.com.ng" referrerPolicy="no-referrer" className="text-dark" target="_blank">
                                    <img className="img-fluid center-block mx-auto grayscale" src={Security} alt="Al-Ansar Security"/>
                                    <p className="text-center">Al-Ansar Security Services</p>
                                </a>
                            </div>

                            <div className="item">
                                <a href="https://alaaka.ng" referrerPolicy="no-referrer" className="text-dark" target="_blank">
                                    <img className="img-fluid center-block mx-auto grayscale" src={AcademySuleja} alt="Al-Ansar Academy Suleja"/>
                                    <p className="text-center">Al-Ansar Academy, Suleja</p>
                                </a>
                            </div>

                            <div className="item">
                                <a href="https://alaasu.ng" referrerPolicy="no-referrer" className="text-dark" target="_blank">
                                    <img className="img-fluid center-block mx-auto grayscale" src={AcademyKano} alt="Al-Ansar Academy Kano"/>
                                    <p className="text-center">Al-Ansar Academy, Kano</p>
                                </a>
                            </div>

                            <div className="item">
                                <a href="https://aum.edu.ng" referrerPolicy="no-referrer" className="text-dark" target="_blank">
                                    <img className="img-fluid center-block mx-auto grayscale" src={Cleaning} alt="Al-Ansar Cleaning Services"/>
                                    <p className="text-center">Al-Ansar Cleaning & Hygiene Services</p>
                                </a>
                            </div>

                            <div className="item">
                                <a href="https://al-ansarradioandtv.com.ng/" referrerPolicy="no-referrer" className="text-dark" target="_blank">
                                    <img className="img-fluid center-block mx-auto grayscale" src={Radio} alt="Al-Ansar Radio"/>
                                    <p className="text-center">Al-Ansar Radio & TV</p>
                                </a>
                            </div>

                            <div className="item">
                                <a href="https://aum.edu.ng/" referrerPolicy="no-referrer" className="text-dark" target="_blank">
                                    <img className="img-fluid center-block mx-auto grayscale" src={Hospital} alt="Al-Ansar Hospital"/>
                                    <p className="text-center">Al-Ansar Women & Children's Hospital</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PartnersSection;
