import gallery1 from './images/images1.jpg'
import gallery2 from './images/images2.jpg'
import gallery3 from './images/images3.jpg'
import gallery4 from './images/images4.jpg'

export const gallery_images_data = [
    { images: gallery1 },
    { images: gallery2 },
    { images: gallery3 },
    { images: gallery4 },
];