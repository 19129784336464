import React from "react";

export default function Loader () {
    return <div className="preloader-area">
                <div className="spinner">
                    <div className="inner">
                        <div className="disc"></div>
                        <div className="disc"></div>
                        <div className="disc"></div>
                    </div>
                </div>
            </div>
}
