import React from "react";
import Banner from "../../common/banner/banner";
import bg3 from "../../../images/bg/09.jpg";
import { Link } from "react-router-dom";


const Fees = (props)=>{


    return(
        <>
        <Banner
        Image={bg3}
        Title={"Tuition fees and student funding"}
        breadcrumb={["Admissions", "Fees"]}
        height="50px"
        />
        <section className="space-pb pt-3">
        <div className="container">
          <div className="row">
          <p className="mb-4">Education at Al-Ansar is focused on human capital development. Our fees are very affordable and offers great value for money.</p>
            <div className="col-md-4 pb-4 pb-md-0">
              <div className="pricing">
                <h4 className="pricing-title">Tuition</h4>
                <p className="mb-0">Find out everything you need to know to plan your student finances</p>
                <Link className="btn btn-light mt-5" to={'/fees/tuition'}>Find out more</Link>
              </div>
            </div>
            <div className="col-md-4 pb-4 pb-md-0">
              <div className="pricing active">
                <h4 className="pricing-title">Accommodation</h4>
                <p className="mb-0">Accommodation right in the university campus</p>
                <Link className="btn btn-light mt-5" to={'/fees/accommodation'}>Find out more</Link>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pricing">
                <h4 className="pricing-title">Feeding</h4>
                <p className="mb-0">The University has different cafeteria's with different types of meals</p>
                <Link className="btn btn-light mt-5" to={'/fees/feeding'}>Find out more</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
        </>
        
    )
}

export default Fees;