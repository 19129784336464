import React from "react";
import { Link } from "react-router-dom";
import { encryptData, formatDateAndTime } from "../../../../resources/url";
import * as DOMPurify from 'dompurify';


const JobCards = (props) => {

    return (
        <>
            {
                props.jobList.length > 0 ?
                    <>
                        {
                            props.jobList.map((item, key) => {
                                return (
                                    <div className="events shadow p-4 bg-white border-radius mb-4" key={key}>
                                        <div className="row">
                                            <div className="col-lg-9 col-xs-12 align-self-center">
                                                <div className="events-content">
                                                    <div className="events-tag">
                                                    {item.Urgent.toString() === '1' && 
                                                    <a className="badge-success mb-2">
                                                        Urgent</a>}
                                                    </div>
                                                    <Link className="text-dark h5" to={`/careers/job-details/${encryptData(item.EntryID.toString())}`}>{item.Position}</Link>

                                                    <div className="events-meta my-2">
                                                        <ul className="list-unstyled mb-0 d-flex">
                                                            <li className="mr-3"><a href="#"><i className="fas fa-calendar-alt pr-1" /> 
                                                            <span className="text-danger">{formatDateAndTime(item.ClosingDate, "date")}</span></a></li>
                                                        </ul>
                                                    </div>
                                                    <p className="text-dark mb-lg-0"> 
                                                    <span  dangerouslySetInnerHTML={{ __html: `${DOMPurify.sanitize(item.Description.substring(0,100))}` }}/></p>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 align-self-center text-lg-right text-left">
                                            {/* to={`/jobs/details/${encryptData(item.EntryID.toString())}`} */}
                                                <Link className="btn btn-dark btn-round"  to={`/careers/job-details/${encryptData(item.EntryID.toString())}`} >Apply</Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </>
                    : <></>
            }</>


    )
}
export default JobCards;