import React, { useEffect, useState } from "react";
import {
    setCourseDataDetails,
    setCourseListDetails,
    setDepartmentListDetails,
    setEventDetails,
    setFacultyDataDetails,
    setFacultyListDetails,
    setHomepageContentDetails, setHomeSliderDetails,
    setNewsDetails, setPartnersDetails,
} from "./actions/setactiondetails";
import { connect } from "react-redux";
import axios from "axios";
import { serverLink } from "./resources/url";
import PageRoutes from "./component/pageroutes/pageroutes";
import DataLoader from "./component/common/dataLoader/dataLoader";

function App(props) {
  const [isLoading, setIsLoading] = useState(
      !(!(props.facultyList.length > 0 && props.facultyDetail.length > 0 && props.departmentList.length > 0 &&
          props.courseList.length > 0 && props.courseDetails > 0 && props.newsList > 0 && props.eventList > 0 && props.slider))
  );

  const getData = async () => {
      await axios
          .get(`${serverLink}slider/homepage`)
          .then((result) => {
              props.setOnSliderDetails(result.data)
          })
          .catch((error) => {
              console.log("error", error);
          });

    await axios
        .get(`${serverLink}academics/faculty/academic/list`)
        .then((response) => {
          props.setOnFacultyListDetails(response.data);
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });
    await axios
        .get(`${serverLink}academics/department/academic/list`)
        .then((response) => {
          props.setOnDepartmentListDetails(response.data);
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });

    await axios
        .get(`${serverLink}academics/faculty/details`)
        .then((response) => {
          props.setOnFacultyDataDetails(response.data);
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });

    await axios
        .get(`${serverLink}academics/course/view/list`)
        .then((response) => {
          props.setOnCourseListDetails(response.data);
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });

    await axios
        .get(`${serverLink}academics/course/details`)
        .then((response) => {
          props.setOnCourseDataDetails(response.data);
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });

    await axios
        .get(`${serverLink}news/all/active`)
        .then((result) => {
          const data = result.data;
          props.setOnNewsDetails(data);
        })
        .catch((error) => {
          console.log("error", error);
        });

    await axios
        .get(`${serverLink}event/all/active`)
        .then((result) => {
          const data = result.data;
          props.setOnEventDetails(data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    await axios
        .get(`${serverLink}general/homepage/content`)
        .then((result) => {
          const data = result.data;
          props.setOnHomepageContentDetails(data[0]);
        })
        .catch((error) => {
          console.log("error", error);
        });
    await axios
        .get(`${serverLink}general/partner/list`)
        .then((result) => {
          const data = result.data;
          props.setOnPartnersDetails(result.data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    setIsLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
      <div className="App">{isLoading ? <DataLoader /> : <PageRoutes />}</div>
  );
}
const mapStateToProps = (state) => {
  return {
    facultyList: state.FacultyListDetails,
    facultyDetail: state.FacultyDataDetails,
    courseList: state.CourseListDetails,
    courseDetails: state.CourseDataDetails,
    departmentList: state.DepartmentListDetails,
    eventList: state.EventDetails,
    newsList: state.NewsDetails,
      slider: state.SliderDetails,
      partners: state.PartnersDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnFacultyListDetails: (p) => {
      dispatch(setFacultyListDetails(p));
    },
    setOnFacultyDataDetails: (p) => {
      dispatch(setFacultyDataDetails(p));
    },
    setOnDepartmentListDetails: (p) => {
      dispatch(setDepartmentListDetails(p));
    },
    setOnCourseListDetails: (p) => {
      dispatch(setCourseListDetails(p));
    },
    setOnCourseDataDetails: (p) => {
      dispatch(setCourseDataDetails(p));
    },
    setOnEventDetails: (p) => {
      dispatch(setEventDetails(p));
    },
    setOnNewsDetails: (p) => {
      dispatch(setNewsDetails(p));
    },
    setOnHomepageContentDetails: (p) => {
      dispatch(setHomepageContentDetails(p));
    },
    setOnSliderDetails: (p) => {
      dispatch(setHomeSliderDetails(p));
    },
    setOnPartnersDetails: (p) => {
      dispatch(setPartnersDetails(p));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
