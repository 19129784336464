import React  from "react";
import {Link } from "react-router-dom";
import { connect } from "react-redux";
import PageName from "../common/pagename/pagename";
import Banner from "../common/banner/banner";
import bg from "../../images/bg/03.jpg";
import {
    historyStatement,
        missionStatement,
        projectAddress,
        projectName,
        projectPhone,
        projectURL,
        serverLink,
        shortCode,
        visionStatement,
} from "../../resources/url";
import * as DOMPurify from 'dompurify';


function OurCampus (props) {
    return   (
        <>
            <PageName page={"Our Campus"} />
            <Banner
                Image={bg}
                Title={"Our Campus"}
                breadcrumb={["Home", "Our Campus"]}
            />
            <div className="academics-details-area pt-4 mb-5 pb-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="academics-details">
                                <div className="details">
                                    {/*<h3 className="text-uppercase">*/}
                                    {/*    Our Campus*/}
                                    {/*</h3>*/}
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html:  DOMPurify.sanitize(props.homeData.CampusContent),
                                        }} style={{textAlign: 'justify'}}
                                    />
                                    <Link to={"/admission/application"} className="btn btn-primary">
                                        Start your application <i className="flaticon-next" />
                                    </Link>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-6 img-thumbnail" style={{
                            backgroundImage: props.homeData.CampusImage !== undefined ? props.homeData.CampusImage.includes("simplefileupload") ? props.homeData.CampusImage :  `url(${serverLink}public/uploads/${shortCode}/content_uploads/${props.homeData.CampusImage})` : '',
                            height: '400px',
                            backgroundSize: 'cover'
                        }}>
                        </div>

                    </div>
                    <section className="space-ptb">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <div className="accordion" id="career-opportunities">
                                        <div className="card">
                                            <div className="accordion-icon card-header" id="headingOne">
                                                <h4 className="mb-0">
                                                    <button className="btn" type="button" data-toggle="collapse"
                                                            data-target="#security-manager" aria-expanded="true"
                                                            aria-controls="security-manager">
                                                        Our Mission
                                                    </button>
                                                </h4>
                                            </div>
                                            <div id="security-manager" className="collapse show"
                                                 aria-labelledby="headingOne"
                                                 data-parent="#career-opportunities">
                                                <div className="card-body">
                                                    {missionStatement}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="accordion-icon card-header" id="headingTwo">
                                                <h4 className="mb-0">
                                                    <button className="btn" type="button" data-toggle="collapse"
                                                            data-target="#vision" aria-expanded="false"
                                                            aria-controls="vision">
                                                        Our Vision
                                                    </button>
                                                </h4>
                                            </div>
                                            <div id="vision" className="collapse show"
                                                 aria-labelledby="headingTwo"
                                                 data-parent="#career-opportunities">
                                                <div className="card-body">
                                                    {visionStatement}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        homeData: state.HomepageContentDetails,
    };
};

export default connect(mapStateToProps, null)(OurCampus );