import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { serverLink } from "../../../../resources/url";
import {
    setApplicationDataDetails,
    setApplicationLoginDetails,
    setGeneralDetails,
} from "../../../../actions/setactiondetails";
import Sidebar from "./sidebar";
import { toast } from "react-toastify";
import { subjects } from "../../../common/ssce/subjects";
import Loader from "../../../common/loader/loader";

function Jamb(props) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [userData] = useState(props.applicationLogin);
    const [jambData, setJambData] = useState(props.applicant_data.jamb ?? []);
    const [jambResult, setJambResult] = useState({
        matricNumber: "",
        examYear: "",
        subject1: "",
        jambScore1: 0,
        subject2: "",
        jambScore2: 0,
        subject3: "",
        jambScore3: 0,
        subject4: "",
        jambScore4: 0,
        AppID: props.applicationData.EntryID,
    });

    const allApplicationData = async () => {
        await axios
            .get(`${serverLink}application/ug/application/data/${props.applicationData.EntryID}`)
            .then((response) => {
                props.setOnApplicationDataDetails(response.data.application_data)
                // props.setOnGeneralDetails(response.data);
                setJambData(response.data.jamb);
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleChange = (e) => {
        if (e.target.id.includes("jambScore"))
            setJambResult({ ...jambResult, [e.target.id]: parseInt(e.target.value) });
        else setJambResult({ ...jambResult, [e.target.id]: e.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (props.applicationData.AdmissionType === "UTME") {
            for (let value in jambResult) {
                if (jambResult[value] === "") {
                    toast.error(`${value} can't be empty`);
                    return false;
                }
            }
        }


        if(jambResult.matricNumber === "" || jambResult.examYear === ""){
            toast.error("please fill all fields");
            return false;
        }


        await axios
            .post(`${serverLink}application/jamb/result`, jambResult)
            .then((response) => {
                if (response.data.message === "success") {
                    toast.success(`Jamb Result Added`);
                    allApplicationData();
                    onNext();
                } else {
                    toast.error(
                        `Something went wrong uploading jamb result. Please try again!`
                    );
                }
            })
            .catch((error) => {
                toast.error(
                    `Something went wrong. Please check your connection and try again!`
                );
            });
    };

    const handleNewEntry = async () => {
        await axios
            .delete(
                `${serverLink}application/jamb/delete/${props.applicationData.EntryID}`
            )
            .then((res) => {
                if (res.data.message === "success") {
                    allApplicationData();
                }
            })
            .catch((err) => {
                console.log("NETWORK ERROR", err);
            });
    };

    const onPrevious = () => {
        navigate("/admission/application/undergraduate/secondary-school-result");
    };

    const onNext = () => {
        navigate("/admission/application/undergraduate/guardian-information");
    };

    useEffect(() => {
        allApplicationData();
    }, [])

    let jamb_total_score = 0;
    return loading ? (
        <Loader />
    ) : (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-8 text-center">
                    <div className="section-title">
                    </div>
                </div>
            </div>
            <div className="row">
                <Sidebar userData={userData} />
                <div className="col 9">

                    <div className="register">
                        {jambData.length < 1 ? (
                            <form className="login bg-white  shadow p-5 mb-6" onSubmit={handleSubmit}>
                                <h5 className="mb-3">{props.applicationData.AdmissionType} Details</h5>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="matricNumber">
                                                Matric Number <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="matricNumber"
                                                className="form-control"
                                                placeholder="Matric Number"
                                                // required
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="examYear">
                                                Examination Year{" "}
                                                <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="number"
                                                id="examYear"
                                                min={1980}
                                                max={new Date().getFullYear()}
                                                className="form-control"
                                                placeholder="Examination Year"
                                                // required
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    {
                                        props.applicationData.AdmissionType === "UTME"
                                        &&
                                        <>
                                            <div className="col-8 ">
                                                <div className="form-group">
                                                    <label htmlFor="subject1">
                                                        Subject <span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        id="subject1"
                                                        name="subject"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Select Option</option>
                                                        {subjects.map((s, i) => (
                                                            <option key={i} value={s}>
                                                                {s}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-4 ">
                                                <div className="form-group">
                                                    <label htmlFor="jambScore1">
                                                        Score <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="number"
                                                        id="jambScore1"
                                                        name="score"
                                                        min={0}
                                                        max={100}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-8 ">
                                                <div className="form-group">
                                                    <label htmlFor="subject2">
                                                        Subject <span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        id="subject2"
                                                        name="subject"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Select Option</option>
                                                        {subjects.map((s, i) => (
                                                            <option key={i} value={s}>
                                                                {s}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-4 ">
                                                <div className="form-group">
                                                    <label htmlFor="jambScore2">
                                                        Grade <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        max={100}
                                                        id="jambScore2"
                                                        name="score"
                                                        onChange={handleChange}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-8">
                                                <div className="form-group">
                                                    <label htmlFor="subject3">
                                                        Subject <span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        id="subject3"
                                                        name="subject"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Select Option</option>
                                                        {subjects.map((s, i) => (
                                                            <option key={i} value={s}>
                                                                {s}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label htmlFor="jambScore3">
                                                        Grade <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="number"
                                                        id="jambScore3"
                                                        min={0}
                                                        max={100}
                                                        name="score"
                                                        onChange={handleChange}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-8">
                                                <div className="form-group">
                                                    <label htmlFor="subject4">
                                                        Subject <span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        id="subject4"
                                                        name="subject"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Select Option</option>
                                                        {subjects.map((s, i) => (
                                                            <option key={i} value={s}>
                                                                {s}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-4 ">
                                                <div className="form-group">
                                                    <label htmlFor="jambScore4">
                                                        Grade <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="number"
                                                        id="jambScore4"
                                                        min={0}
                                                        max={100}
                                                        name="score"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>

                                            <div className={"alert alert-info col-md-12 mt-3"}>
                                                TOTAL:{" "}
                                                {jambResult.jambScore1 +
                                                    jambResult.jambScore2 +
                                                    jambResult.jambScore3 +
                                                    jambResult.jambScore4}
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="col-md-12 mt-3 mb-2">
                                    <div className="filters-group mb-lg-4 text-center">
                                        <button className="btn btn-dark  active" onClick={onPrevious}> Previous</button>
                                        <button className="btn btn-dark w-50  active" type="submit">  Save and Continue</button>
                                    </div>
                                </div>

                            </form>
                        ) : (
                            <>
                                {jambData.length > 0 && (
                                    <>
                                        <h4>Matric Number: {jambData[0].MatricNumber}</h4>
                                        <h4>Exam Year: {jambData[0].ExaminationYear}</h4>
                                        <button
                                            className="btn btn-secondary btn-sm float-end"
                                            onClick={handleNewEntry}
                                        >
                                            Edit Score
                                        </button>
                                        <table className="table table-bordered pt-5">
                                            <thead>
                                                <tr>
                                                    <th>Subject</th>
                                                    <th>Score</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {jambData.map((jamb, index) => {
                                                    jamb_total_score += jamb.SubjectScore;
                                                    return (
                                                        <tr key={index}>
                                                            <td>{jamb.SubjectName}</td>
                                                            <td>{jamb.SubjectScore}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th>Total:</th>
                                                    <th>{jamb_total_score}</th>
                                                </tr>
                                            </tfoot>
                                        </table>

                                        <div className="col-md-12 mt-3 mb-2 pb-5">
                                            <div className="filters-group mb-lg-4 text-center">
                                                <button className="btn btn-dark  active" onClick={onPrevious}> Previous</button>
                                                <button className="btn btn-dark w-50  active" onClick={onNext}>  Save and Continue</button>
                                            </div>
                                        </div>

                                    </>
                                )}
                            </>
                        )}
                    </div>

                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        applicationLogin: state.ApplicationLoginDetails,
        applicant_data: state.GeneralDetails,
        applicationData: state.ApplicationDataDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnApplicationLoginDetails: (p) => {
            dispatch(setApplicationLoginDetails(p));
        },
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        },
        setOnApplicationDataDetails: (p) => {
            dispatch(setApplicationDataDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Jamb);
