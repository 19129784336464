import React from "react";
import Banner from "../common/banner/banner";
import bg3 from "../../images/bg/03.jpg";

const AcademicRegistry = (props) => {


    return (
        <>
            <Banner
                Image={bg3}
                Title={"Academic Registry"}
                breadcrumb={["Academics", "Academic Registry"]}
                height="50px"
            />

            <section className="space-ptb">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="mb-4">The Academic Registry acts as the custodian for student records and academic information at the university. This includes interacting with both students and faculty to provide services in the following areas:</p>
                            <ul className="mb-4 list-unstyled">
                                <li className="mb-2"><i className="fas fa-chevron-right pr-2" />Making a decision to do something</li>
                                <li className="mb-2"><i className="fas fa-chevron-right pr-2" />Focus is having the unwavering attention to complete what you set out to do.</li>
                                <li className="mb-2"><i className="fas fa-chevron-right pr-2" />Nothing changes until something moves</li>
                                <li className="mb-2"><i className="fas fa-chevron-right pr-2" />Commit your decision to paper</li>
                                <li className="mb-2"><i className="fas fa-chevron-right pr-2" />Execution is the single biggest factor in achievement</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AcademicRegistry;