import React from "react";
import Banner from "../common/banner/banner";
import bg3 from "../../images/bg/03.jpg";

const LearningGoals = (props) => {
    return (
        <>
            <Banner
                Image={bg3}
                Title={"Learning Goals"}
                breadcrumb={["Academics", "Learning Goals"]}
                height="50px"
            />

            <section className="space-ptb">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="mb-4">Education at Al-Ansar comprises formal and informal learning experiences in the classroom, on campus, and beyond. Through the multiple dimensions of students' life, Al-Ansar provides an academic community that aims to help them achieve a variety of goals.</p>

                            <h5 className="text-primary mt-4">1. Disciplinary Knowledge</h5>
                            <p className="mb-4">demonstrate a systematic or coherent understanding of an academic field of study</p>

                            <h5 className="text-primary mt-4">2. Critical Thinking</h5>
                            <p className="mb-4">apply analytic thought to a body of knowledge; evaluate arguments; identify relevant assumptions or implications; formulate coherent arguments</p>

                            <h5 className="text-primary mt-4">3. Information Literacy</h5>
                            <p className="mb-4">Access, evaluate, and use a variety of relevant information sources</p>

                            <h5 className="text-primary mt-4">4. Engagement in the Process of Discovery or Creation</h5>
                            <p className="mb-0">For example, demonstrate the ability to work productively in a laboratory setting, studio, library, or field environment</p>
                            <h5 className="text-primary mt-4">5. Self-Directed Learning</h5>
                            <p className="mb-0">Work independently; identify appropriate resources; take initiative; manage a project through to completion</p>

                            <h5 className="text-primary mt-4">6. Communication Skills</h5>
                            <p className="mb-0">Express ideas clearly in writing; speak articulately; communicate with others using media as appropriate; work effectively with others</p>

                            <h5 className="text-primary mt-4">7. Scientific and Quantitative Reasoning</h5>
                            <p className="mb-0">Demonstrate the ability to understand cause and effect relationships; define problems; use symbolic thought; apply scientific principles; solve problems with no single correct answer</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LearningGoals;