import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setHRStaffDataDetails, setHRStaffListDetails } from "../../actions/setactiondetails";
import { serverLink, shortCode } from "../../resources/url";
import Banner from "../common/banner/banner";
import Loader from "../common/loader/loader";
import * as DOMPurify from 'dompurify';


const StaffDetails = (props) => {
    const dispatch = useDispatch();
    const { slug } = useParams();
    const navigate = useNavigate();

    const [titles, setTitles] = useState([]);
    const [designation, setDesignation] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [staffRelatedDocuments, setStaffRelatedDocuments] = useState({
        qualifications: [],
        modules: [],
        publications: [],
    });
    const [qoutes, setQuotes] = useState([])


    useEffect(()=>{
        axios.get("https://type.fit/api/quotes").then((res)=>{
            const item = res.data[Math.floor(Math.random()*res.data.length)];
            setQuotes(item);
        })
    }, [])


    let staffRecord = props.hrStaffList.filter((item) => item.Slug === slug);
    const [staffDetail, setStaffDetail] = useState(
        staffRecord.length > 0
            ? props.hrStaffData.filter(
                (item) => item.StaffID === staffRecord[0].StaffID
            )
            : []
    );

    const getHRStaffList = async () => {
        await axios
            .get(`${serverLink}academic/titles/`)
            .then((response) => {
                setTitles(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });

        await axios
            .get(`${serverLink}academic/designation/`)
            .then((response) => {
                setDesignation(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });

        await axios
            .get(`${serverLink}academic/departments/`)
            .then((response) => {
                setDepartments(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
    };

    useEffect(() => {
        const slug_record = slug;
        staffRecord.push(
            props.hrStaffList.filter((item) => item.Slug === slug_record)
        );
        setStaffDetail(
            staffRecord.length > 0
                ? props.hrStaffData.filter(
                    (item) => item.StaffID === staffRecord[0].StaffID
                )
                : []
        );
    }, [slug]);

    const getStaffData = async () => {
        await axios
            .get(`${serverLink}academic/staff/all/`)
            .then((response) => {
                const data = response.data;

                const staff_data = data.filter((item) => item.Slug === slug);
                props.setOnHRStaffListDetails(response.data);
                if (data.filter((item) => item.Slug === slug).length < 1) {
                    navigate("/");
                } else {
                    staffRecord.push(staff_data);
                }
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });

        await axios
            .get(`${serverLink}academic/staff/data/`)
            .then((response) => {
                props.setOnHRStaffDataDetails(response.data);
                setStaffDetail(
                    response.data.filter(
                        (item) => item.EntryID === staffRecord[0].EntryID
                    )
                );
                if (staffDetail.length < 1) navigate("/");
                else console.log(staffDetail);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
    };

    if (staffDetail.length < 1) {
        if (loading) {
            getStaffData().then((r) => { });
            setLoading(false);
        }
    }

    useEffect(() => {
        getStaffRelatedDocument().then((r) => { });
        getHRStaffList().then((r) => { });
    }, [dispatch]);

    const getStaffRelatedDocument = async () => {
        await axios
            .get(`${serverLink}academic/staff/${staffRecord[0].StaffID}`)
            .then((response) => {
                setStaffRelatedDocuments(response.data);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
    };

    const updateDownloadCount = async (id) => {
        await axios
            .put(`${serverLink}academic/update/download/count/${id}`)
            .then((response) => { })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
    };

    if (staffDetail.length < 1) {
        if (loading) {
            getStaffData().then((r) => { });
            setLoading(false);
        }
    }
    return loading ? (<Loader />) : (
        <>
            <Banner
                Image={staffDetail[0].Image !== null ? staffDetail[0].Image.includes("simplefileupload") ? staffDetail[0].Image :  `${serverLink}public/uploads/${shortCode}/hr/document/${staffDetail[0].Image}` : ""}
                Title={staffDetail[0].FirstName + " " + staffDetail[0].Surname}
                breadcrumb={["Staff Directorate", "Staff Details"]}
                height="50px"
            />
            <section className="space-pt">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 pb-5">
                            <div className="row">
                                <div className="col-md-4 mb-4 mb-md-0">
                                    <div className="team-single is-sticky">
                                        <div className="team-img">
                                            <img className="img-fluid border-radius" 
                                             src={staffDetail[0].Image !== null ? staffDetail[0].Image.includes("simplefileupload") ? staffDetail[0].Image :  `${serverLink}public/uploads/${shortCode}/hr/document/${staffDetail[0].Image}` : ""}
                                            alt="" />
                                        </div>

                                        <div className="social mt-4 text-center">
                                            <ul className="pl-0">
                                                <li><a target={"_blank"} href={staffDetail[0].Facebook}> <i className="fab fa-facebook-f" /> </a></li>
                                                <li><a target={"_blank"} href={staffDetail[0].Researchgate}> <i className="fab fa-researchgate" /> </a></li>
                                                <li><a target={"_blank"} href={staffDetail[0].Twitter}> <i className="fab fa-twitter" /> </a></li>
                                                <li><a target={"_blank"} href={staffDetail[0].LinkedIn}> <i className="fab fa-linkedin" /> </a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="team-info">
                                        <a href="#" className="team-name h4">{staffDetail[0].FirstName + " " + staffDetail[0].MiddleName + " " + staffDetail[0].Surname}</a>
                                        <h6 className="border-bottom text-primary my-3 pb-3">
                                            {titles.length > 0 &&
                                                titles.filter((i) =>
                                                    i.EntryID ===
                                                    staffDetail[0].TitleID
                                                )
                                                    .map((r) => r.TitleName)} {""}
                                        </h6>
                                        <div className="d-flex">
                                            <h6>Department:</h6>
                                            <label className="ml-3">
                                                {departments.length > 0 &&
                                                    departments.filter((i) =>
                                                        i.DepartmentCode ===
                                                        staffDetail[0].DepartmentCode
                                                    )
                                                        .map((r) => r.DepartmentName)

                                                }
                                            </label>
                                        </div>
                                        <div className="d-flex">
                                            <h6>Phone:</h6>
                                            <label className="ml-3">{staffDetail[0].PhoneNumber}</label>
                                        </div>
                                        <div className=" d-flex">
                                            <h6>Email:</h6>
                                            <label className="ml-3">{staffDetail[0].OfficialEmailAddress}</label>
                                        </div>
                                        <div className=" d-flex">
                                            <h6>Address:</h6>
                                            <label className="ml-3">{staffDetail[0].ContactAddress}</label>
                                        </div>
                                        <div className="d-flex">
                                            <h6>Profile Hit:</h6>
                                            <label className="ml-3">{staffDetail[0].Hits}</label>
                                        </div>
                                        <div className="section-title mt-5">
                                            <h4 className="title">Biography</h4>
                                            <p className="mt-4" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(staffDetail[0]?.Biography) }} />
                                        </div>
                                    </div>
                                    <div className="blockquote mt-5">
                                        <div className="blockquote-info">
                                            <div className="blockquote-quote">
                                                <i className="fas fa-quote-left" />
                                            </div>
                                            <div className="blockquote-content">
                                                *{qoutes.text}*<br/>
                                                <strong className="text-primary">{qoutes.author}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-title mt-5">
                                        <h4 className="title">Qualifications</h4>
                                        <div className="list">
                                            <ul className="pl-0">
                                                {staffRelatedDocuments.qualifications
                                                    .length > 0 ? (
                                                    <>
                                                        {staffRelatedDocuments.qualifications.map(
                                                            (r, index) => (
                                                                <li key={index}>
                                                                    <i className="fas fa-check text-primary pr-2" />
                                                                    {r.Discipline} {r.QualificationTitle} {r.QualificationTitle} at {r.InstitutionName} &emsp;&emsp;| {r.Year}
                                                                </li>
                                                            )
                                                        )}
                                                    </>
                                                ) : (
                                                    <>{null}</>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="section-title">
                                        <h4 className="title">Modules Taken</h4>
                                        <div className="table table-responsive">
                                            <table className="table-hover w-100">
                                                <thead>
                                                    <tr>
                                                        <td>SN</td>
                                                        <td>Module Code</td>
                                                        <td>Module Descrition</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {staffRelatedDocuments.modules.map(
                                                        (r, index) => (
                                                            <tr key={index}>
                                                                <td>{index +1}</td>
                                                                <td>{r.ModuleCode}:{" "}</td>
                                                                <td>{r.ModuleDescription}{" "}</td>
                                                            </tr>
                                                        )
                                                    )}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="section-title">
                                        <h4 className="title">Publications</h4>
                                        <div className="table table-responsive">
                                            <table className="table-hover w-100">
                                                <thead>
                                                    <tr>
                                                        <td>SN</td>
                                                        <td>WorkTitle</td>
                                                        <td>Authors</td>
                                                        <td>PublishedYear</td>
                                                        <td>UploadFile</td>
                                                        <td>
                                                            Download/View
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {staffRelatedDocuments.publications.length > 0 &&
                                                        staffRelatedDocuments.publications.map(
                                                            (r, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{r.WorkTitle}</td>
                                                                    <td>{r.Authors}</td>
                                                                    <td>{r.PublishedYear}</td>
                                                                    <td><a href={`${serverLink}public/uploads/${shortCode}/academic/publication/${r.UploadFile ? r.UploadFile : ""}`}
                                                                        target="_blank"
                                                                        onClick={() =>
                                                                            updateDownloadCount(r.EntryID)
                                                                        }
                                                                    >
                                                                        {" "}
                                                                        Download Paper
                                                                    </a></td>
                                                                    <td>{r.DownloadCount}/{r.ViewCount}</td>
                                                                </tr>
                                                            )
                                                        )}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        hrStaffList: state.HRStaffListDetails,
        hrStaffData: state.HRStaffDataDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnHRStaffListDetails: (p) => {
            dispatch(setHRStaffListDetails(p));
        },
        setOnHRStaffDataDetails: (p) => {
            dispatch(setHRStaffDataDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffDetails);