import React from "react";
import { Link } from "react-router-dom";

export default function CourseContent(props) {
    return (
        <div className="col-lg-9">
            <div className="courses-left-content">
                {props.courseList.length > 0 ? (
                    <div className="row">
                        {props.courseList.map((item, key) => {
                            return<div className="col-lg-4 col-md-6 mb-4" key={key}>
                            <div className="course-item">
                                <Link to={`/course/${item.Slug}`} className="badge-primary" style={{ padding: "5px", borderRadius: "5px" }} >{item.FacultyName.replace("College of", "")}</Link>

                                <div className="course-conten">
                                    <h5 className="mb-3">
                                    <Link to={`/course/${item.Slug}`}>{item.CourseName}</Link>
                                    </h5>
                                    <div className="course-meta">
                                        <ul className="list-unstyled mb-0">
                                            <li><i className="fa fa-clock pr-2" />{item.Duration} {item.DurationType}</li>
                                            <li><i className="fa fa-user pr-2" />{item.CourseClass}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        })}

                    </div>
                ) : (
                    <h3>No Course Found</h3>
                )}

            </div>
        </div>
    );
}


