import React from 'react'
import PageName from '../../common/pagename/pagename'
import Banner from '../../common/banner/banner'
import bg from "../../../images/bg/03.jpg"
import Founder from "../../../images/facilities/pres.jpeg"

export default function TheFounder(props) {
    return (
        <>
            <PageName page={"The Founder"} />
            <Banner
                Image={bg}
                Title={"The Founder"}
                breadcrumb={["Home", "The Founder"]}
            />
            <div className="academics-details-area pt-4 mb-5 pb-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 text-center">
                            <img src={Founder} className='img-thumbnail' style={{
                                backgroundImage: Founder,
                                height: '400px',
                                backgroundSize: 'cover'
                            }} />
                            <h6 className='text-center mt-2'>
                                Dr. Mohammed Kyari Dikwa mni, DSC, (ACCT & FIN.)<br />
                                <small>
                                    Ph.D. (ACCT & FIN.), MBA (FIN),
                                    B.Sc (ACCTS), ND (ACCT. & FIN),
                                    FCNA, ACA, FCTI, FCMA, FCPA, FICA,
                                    MNIM, MNIM, MCIPS
                                </small>
                            </h6>
                        </div>
                        <div className="col-md-6">
                            <div className="academics-details">
                                <div className="details">
                                    <p className='text-justify'>
                                        Dr. Mohammed Kyari Dikwa mini was born on the 3rd of January, 1960 in Dikwa town of Borno State, Nigeria. 
                                        He is one of the leading experts in the Public Financial
                                        Management (PFM) Reforms in the Nigerian Public Service.
                                        He has Doctorate Degree in Accounting and Finance, master’s
                                        degree in finance and Bachelor of Science Degree in
                                        Accountancy. He had attended the Senior Executive Course
                                        34, 2012 at the National Institute for Policy and Strategic
                                        Studies, (NIPSS) Kuru. He was awarded Certificates in Public
                                        Financial Management Reforms, Leadership & Governance
                                        Programme, High Performance Leadership Programme and
                                        Treasury Management at the Harvard University, USA,
                                        University of Cambridge, Oxford University UK and Thames
                                        Valley University UK respectively. He was also awarded
                                        Certificate in Advance Corporate Finance from the Business
                                        School for the World (INSEAD- FONTAINEBLEAU,
                                        FRANCE).
                                    </p>
                                    <p className='text-justify'>
                                        Dr. Dikwa is a Chartered Accountant and member of many
                                        Professional Bodies. Dr. Dikwa is a Fellow, Certified National
                                        Accountant, Associate Institute of Chartered Accountant of
                                        Nigeria, Fellow Chartered Institute of Taxation of Nigeria,
                                        among others. Dr. Dikwa has facilitated and attended many
                                        Courses and authored and edited series of Publications to his
                                        Credit. <br />
                                    </p>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-12'>
                            <p className='text-justify'>
                                Dr. Dikwa has been a seasoned examiner for the
                                Annual Federal Civil Service Promotion Evaluation
                                Examinations for years. Amongst his notable achievements in
                                the Federal Service include direct involvement in the
                                Conceptualization and Implementation of some PFM
                                Reforms such as Government Integrated Financial
                                Management Information System (GIFMIS), Integrated
                                Personnel and Payroll Information System (IPPIS), Treasury
                                Single Account (TSA), International Public Sector Accounting
                                Standards (IPSAS), Presidential Initiative on Continuous
                                Audit (PICA), Asset Tracing Team (ATT), Whistleblowing
                                Policy, Fiscal Sustainability Plan (FSP) and Strategic Revenue
                                Growth Initiative (SRGI). Dr. Dikwa has served as the Borno
                                State Accountant-General 1999 – 2004. Dr. Dikwa was the
                                former Chairman Technical Board, Bank of Industries (BOI)
                                and Bank of Agriculture (BOA). He has served on Board of
                                Transcorp Hilton, Defence Industry Corporation,
                                Transmission Company of Nigeria, Distribution Company of
                            </p>
                            <p className='text-justify'>
                                Nigeria (DISCO), Royal Swaziland Sugar Company etc. Dr.
                                Dikwa was the Director in-charge of Ministry of Finance
                                Incorporated (MOFI) later Revenue and Investment
                                Department and Director of Funds both under the Office of
                                the Accountant General of the Federation. He has acted on
                                various occasions as the Accountant-General of the
                                Federation including the last Acting - Appointment on the
                                12th June, 2015. Dr. Dikwa was the Director, Special Projects
                                and Secretary, Presidential Initiative on Continuous Audit
                                (PICA) under the Office of the Honourable Minister of
                                Finance, until his appointment as Permanent Secretary in the
                                Federal Civil Service. Dr. Dikwa is currently a member of
                                Central Working Group for the drafting of Nigeria Agenda
                                2050 and Medium-Term Development Plan for 2030. Dr.
                                Dikwa has been awarded numerous prestigious awards,
                                some of these include Award and Appreciation at the 39th
                                Cambridge International Symposium on Dedication in
                                Promoting International Co-operation and Collaboration in
                                the Prevention and Interdiction of Economic Crime (2022),
                                Humanitarian & Peace Ambassador Award by National
                            </p>
                            <p className='text-justify'>
                                Peace (2021); Honorary Doctorate Degree (D.Sc. Causa) in
                                Accountancy Award by Crescent University, Abeokuta
                                (2021); NorthEast Stars Magazine Man of the Year Award
                                (2020); Meritorious Service Award by the Federal
                                Government of Nigeria (2019), Thinkers’ Magazine man of
                                the year (2019); Nelson Mandela Award for Excellence in
                                Public Service (2017) among others. Dikwa has traversed
                                many countries across the globe and is happily married with
                                children and grand-children. He has settled down as a senior
                                citizen and assumed the position of Founder and President of
                                Al-Ansar Foundation, an NGO engage in providing service to
                                humanity.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
