import React, { useEffect, useState } from "react";
import {
    decryptData, encryptData,
    formatDate,
    projectYoutube,
    projectDomain,
    projectFacebook,
    projectName,
    projectStudentURL,
    projectTwitter,
    projectURL,
    sendEmail,
    serverLink,
} from "../../../../resources/url.jsx";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import {Link, useNavigate} from "react-router-dom";
import PageName from "../../../common/pagename/pagename";
import Loader from "../../../common/loader/loader";
import StateData from "../../../../resources/state_and_lga.json"
import CountryData from "../../../../resources/country.json"
import { showAlert } from "../../../common/sweetalert/sweetalert";
import {formatTime} from "redux-logger/src/helpers";
import NewStudentEnrolmentForm from "./new-student-enrolment-form";
function NewStudentEnrolment(props) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [isFormLoading, setIsFormLoading] = useState(false);
    const [parentData, setParentData] = useState([]);
    const [semesterList, setSemesterList] = useState([]);
    const [courseList, setCourseList] = useState([]);
    let [stateList, setStateList] = useState([]);
    let [lgaList, setLgaList] = useState([]);

    const [formData, setFormData] = useState({
        FirstName: props.applicationLogin.FirstName,
        MiddleName: props.applicationLogin.MiddleName,
        Surname: props.applicationLogin.Surname,
        Gender: props.applicationLogin.Gender,
        EmailAddress: props.applicationLogin.EmailAddress,
        PhoneNumber: props.applicationLogin.PhoneNumber,
        Address: props.applicationLogin.Address,
        StateOfOrigin: props.applicationLogin.StateOfOrigin,
        Lga: props.applicationLogin.Lga,
        Nationality: props.applicationLogin.Nationality,
        DateOfBirth: formatDate(props.applicationLogin.DateOfBirth) ,
        CourseCode: props.applicationData.CourseCode,
        StudentLevel: props.applicationData.DecisionLevel,
        StudentSemester: props.applicationData.DecisionSemester,
        ApplicationID: props.applicationData.EntryID,
        ModeOfEntry: props.applicationData.DecisionType,
        AdmissionSemester: props.applicationData.AdmissionSemester,
        FacultyCode: "",
        IsActive: "1",
        Status: "active",
        ParentName: "",
        ParentPhoneNumber: "",
        ParentAddress: "",
        YearOfAdmission:  new Date().getFullYear().toString(),
        JambAdmissionDeficiency: "0",
        MedicalCertificateOfFitness: "0",
        GuarantorLetter: "0",
        CompletePassport: "0",
        OlevelDeficiency: "0",
        GraduationSemester: "",
        GraduationDate: "",
        IsTransfered: "0",
        IsHostelRequired: "0",
        CertificateNo: "",
        StudentID: "",
        SchoolDomain: projectDomain,
        Password: `${encryptData('123456789')}`,
        InsertedBy: props.applicationData.EntryID
    });

    const stateData = () => {
        StateData.map((state) => {
            if (state.state !== 'Non-Nigerian')
                setStateList(stateList => [...stateList, state.state ])
        });
    }

    const getData = async () => {
        setIsLoading(true)
        setShowForm(false)
        if (formData.ApplicationID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter the applicationID", "error");
            return false;
        }

        await axios.get(`${serverLink}application/applicant_details/${formData.ApplicationID}`)
            .then((result) => {
                if (result.data.appData.length > 0) {
                    let data = result.data.appData;
                    let guardianData = result.data.guardianData;
                    let courseData = result.data.courseData;
                    let semesterData = result.data.semesterData;
                    setParentData(guardianData)
                    setCourseList(courseData)
                    setSemesterList(semesterData)
                    if (guardianData.length > 0) {
                        setFormData({
                            ...formData,
                            FacultyCode: data[0].FacultyCode,
                            ParentName: `${guardianData[0].FirstName} ${guardianData[0].MiddleName} ${guardianData[0].Surname}`,
                            ParentPhoneNumber: guardianData[0].PhoneNumber,
                            ParentAddress: guardianData[0].Address,
                        })
                    }
                    setShowForm(true)
                }else{
                    showAlert(
                        "NOT FOUND",
                        "Student record not found. Please try again!",
                        "error"
                    );
                    setIsLoading(false);
                    return false;
                }
                setIsLoading(false);
            }).catch((err) => {
                console.log("NETWORK ERROR");
            });
    }


    const onEdit = (e) => {
        const id = e.target.id;
        const value = id === "file" ? e.target.files[0] : e.target.value;
        if (e.target.id === 'Nationality') {
            setStateList([])
            if (e.target.value === 'Nigeria') {
                stateData()
            } else {
                StateData.map(state => {
                    if (state.state === 'Non-Nigerian')
                        setStateList(stateList => [...stateList, state.state ])
                });
            }
        }

        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const onSubmit = async () => {

        for (let key in formData) {
            if (
                formData.hasOwnProperty(key) &&
                key !== "MiddleName" &&
                key !== "ParentName" &&
                key !== "ParentPhoneNumber" &&
                key !== "ParentAddress" &&
                key !== "GraduationSemester" &&
                key !== "GraduationDate" &&
                key !== "CertificateNo" &&
                key !== "StudentID"
            ) {
                if (formData[key] === "") {
                    await showAlert("EMPTY FIELD", `Please enter ${key}`, "error");
                    return false;
                }
            }

        }

        setIsFormLoading(true)

        const course_code = formData.CourseCode;
        const year = formData.AdmissionSemester.substr(0,2);
        await axios.get(`${serverLink}staff/student-manager/al-ansar/get-last-student-id/${course_code}/${year}`)
            .then((result) => {
                if (result.data.length > 0) {
                    let last_student_id = result.data[0].StudentID;
                    let indexOfId = last_student_id.split('/')[2];
                    let lastIndex = Number(indexOfId) + 1;

                    const padStudentID = (lastIndex, places) => String(lastIndex).padStart(places, "0");
                    //let new_student_id = `${formData.FacultyCode}/${formData.AdmissionSemester}/${padStudentID(lastIndex, 4)}`
                    let new_student_id = `${formData.FacultyCode}/${course_code}/${year}/${padStudentID(lastIndex, 3)}`

                    let sendData = {...formData, StudentID: new_student_id}

                    axios.post(`${serverLink}staff/student-manager/enrolment`, sendData)
                        .then((result) => {
                            if (result.data.message === "success") {
                                const data = result.data.StudentData;
                                sendEmail(
                                    `${formData.EmailAddress}`,
                                    `IT Services Department`,
                                    "Student IT Accounts",
                                    `${formData.FirstName} ${formData.MiddleName} ${formData.Surname}`,
                                    `
                                     <p>Student ID: <b>${sendData.StudentID}</b></p>
                                    <p>Student Name: <b>${formData.FirstName} ${formData.MiddleName} ${formData.Surname}</b></p>
                                    <p>Email Address: <b>${data.StudentEmail} </b></p>
                                    <p>Course: <b>${formData.CourseCode} </b></p>
                                    <p>Level: <b>${formData.StudentLevel} </b></p>
                                    <p>Semester: <b>${formData.StudentSemester} </b></p>
                                    
                                    <h3 style="padding-top: 20px"><strong>Student Portal (Results, Registration, Timetable, etc.)</strong></h3>
                                    <p>
                                        <ul>
                                            <li>URL: ${projectStudentURL}</li>
                                            <li>Username: ${data.Username}</li>
                                            <li>Password: ${decryptData(formData.Password)}</li>
                                        </ul>
                                    </p>
                                      <p>*If you have forgotten or do not have your user login details, kindly go to IT Services Desk and get a new one or have your Student Portal Account reset. </p>
                                       <h3 style="padding-top: 20px"><strong>Helpful Links</strong></h3>
                                    <p>Website: ${projectURL}</p>
                                    <p>Twitter: ${projectTwitter}</p>
                                    <p>Facebook: ${projectFacebook}</p>
                                    <p>Youtube: ${projectYoutube}</p>      
                                      `,
                                    `Regards,<br/>Admissions Officer<br/>${projectName}`
                                );
                                toast.success("Student Enrolled Successfully");
                                setIsFormLoading('off')
                                setIsFormLoading(false)
                                setTimeout(()=>{
                                    navigate("/admission/application/dashboard");
                                }, 2000)
                            }else if (result.data.message === "exist"){
                                setIsFormLoading('off')
                                setIsFormLoading(false)
                                showAlert(
                                    "ERROR",
                                    "This student has already been enrolled!",
                                    "error"
                                );
                                setTimeout(()=>{
                                    navigate("/admission/application/dashboard");
                                }, 2000)
                            }  else {
                                setIsFormLoading('off')
                                setIsFormLoading(false)
                                showAlert(
                                    "ERROR",
                                    "Something went wrong. Please try again!",
                                    "error"
                                );
                            }
                        })
                        .catch((error) => {
                            setIsFormLoading('off')
                            setIsFormLoading(false)
                            showAlert(
                                "NETWORK ERROR",
                                "Please check your connection and try again!",
                                "error"
                            );
                        });
                }else{



                    let lastIndex = 1;
                    const padStudentID = (lastIndex, places) => String(lastIndex).padStart(places, "0");
                    let new_student_id = `${formData.FacultyCode}/${course_code}/${year}/${padStudentID(lastIndex, 3)}`
                    let sendData = {...formData, StudentID: new_student_id}

                    axios.post(`${serverLink}staff/student-manager/enrolment`, sendData)
                        .then((result) => {
                            if (result.data.message === "success") {
                                const data = result.data.StudentData;
                                sendEmail(
                                    `${formData.EmailAddress} `,
                                    `IT Services Department`,
                                    "Student IT Accounts",
                                    `${formData.FirstName} ${formData.MiddleName} ${formData.Surname}`,
                                    `
                                     <p>Student ID: <b>${sendData.StudentID}</b></p>
                                    <p>Student Name: <b>${formData.FirstName} ${formData.MiddleName} ${formData.Surname}</b></p>
                                    <p>Email Address: <b>${data.StudentEmail} </b></p>
                                    <p>Course: <b>${formData.CourseCode} </b></p>
                                    <p>Level: <b>${formData.StudentLevel} </b></p>
                                    <p>Semester: <b>${formData.StudentSemester} </b></p>
                                    
                                    <h3 style="padding-top: 20px"><strong>Student Portal (Results, Registration, Timetable, etc.)</strong></h3>
                                    <p>
                                        <ul>
                                            <li>URL: ${projectStudentURL}</li>
                                            <li>Username: ${data.Username}</li>
                                            <li>Password: ${decryptData(formData.Password)}</li>
                                        </ul>
                                    </p>
                                      <p>*If you have forgotten or do not have your user login details, kindly go to IT Services Desk and get a new one or have your Student Portal Account reset. </p>
                                       <h3 style="padding-top: 20px"><strong>Helpful Links</strong></h3>
                                    <p>Website: ${projectURL}</p>
                                    <p>Twitter: ${projectTwitter}</p>
                                    <p>Facebook: ${projectFacebook}</p>
                                    <p>Youtube: ${projectYoutube}</p>      
                                      `,
                                    `Regards,<br/>Admissions Officer<br/>${projectName}`
                                );
                                toast.success("Student Enrolled Successfully");
                                setIsFormLoading('off')
                                setIsFormLoading(false)
                                setTimeout(()=>{
                                    navigate("/admission/application/dashboard");
                                }, 2000)
                            } else if (result.data.message === "exist"){
                                setIsFormLoading('off')
                                setIsFormLoading(false)
                                showAlert(
                                    "ERROR",
                                    "This applicant has already been enrolled!",
                                    "error"
                                );
                                setTimeout(()=>{
                                    navigate("/admission/application/dashboard");
                                }, 2000)
                            } else {
                                setIsFormLoading('off')
                                setIsFormLoading(false)
                                showAlert(
                                    "ERROR",
                                    "Something went wrong. Please try again!",
                                    "error"
                                );
                            }
                        })
                        .catch((error) => {
                            setIsFormLoading('off')
                            setIsFormLoading(false)
                            showAlert(
                                "NETWORK ERROR",
                                "Please check your connection and try again!",
                                "error"
                            );
                        });
                }
            }).catch((err) => {
                console.log("NETWORK ERROR");
            });

    };

    useEffect(() => {
        stateData();
        getData();
    }, []);

    return isLoading ? (
        <Loader />
    ) : (
        <>
            <PageName page={"New Student Enrolment"} />
            <div className=" pt-50 pb-70">
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="text-center text-uppercase">New Student Enrolment</h2>
                            <p className="text-center mb-4">
                                Did you get your admission through our online admission system? If yes, kindly validate your account below.
                            </p>

                            <div>
                                {
                                    isLoading ? <Loader /> :
                                        showForm ?
                                            <NewStudentEnrolmentForm data={formData} isFormLoading={isFormLoading} onEdit={onEdit} onSubmit={onSubmit} stateList={stateList} courseList={courseList} semesterList={semesterList} lgaList={lgaList} nationalityList={CountryData} parentData={parentData} />
                                            : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        applicationLogin: state.ApplicationLoginDetails,
        applicationData: state.ApplicationDataDetails,
    };
};


export default connect(mapStateToProps, null)(NewStudentEnrolment);